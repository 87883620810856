import { observer } from 'mobx-react';
import { InferProps, string } from 'prop-types';
import React, { useState } from 'react';
import ClusterSelector from './ClusterSelector';
import Game from './Game';
import GamePhotoClusters from './GamePhotoClusters';
import { Layout } from './Layout';
import PhotoGallery from './PhotoGallery';
import PhotoCluster from './PhotoCluster';
import Cluster from './Cluster';
import { FormattedHTMLMessage } from 'react-intl';

const props = {
  gameID: string.isRequired
}

type GameClassificationProps = InferProps<typeof props>;

const GameClassification: React.FC<GameClassificationProps> = ({ gameID }) => {

  const [state, setState] = useState({
    selected: 0
  });

  return (
    <Layout>
      {({ components: { Grid } }) => (
        <Game gameID={gameID}>
          {({ game }) => (
            <Grid>
              <Grid.Row style={{ margin: '31px 0' }}>
                {
                  game.state.completed
                    ? (
                      <Grid.Column
                        width={8}
                        style={{
                          backgroundColor: "white",
                          height: "700px",
                          overflowY: 'auto',
                          overflowX: 'hidden',
                          padding: ' 0',
                          position: 'relative'
                        }}
                        floated="right"
                      >
                        <PhotoCluster style={{ color: '#f44b48', fontWeight: 300, fontSize: '14px' }}>
                          {
                            game.state.clusters.map((cluster, index) => (
                              <PhotoCluster.Item key={index}>
                                <Cluster cluster={cluster} active={!!game.selectedCluster && (cluster.id === game.selectedCluster.id)} onClick={(e, cluster) => game.setSelectedCluster(cluster)} />
                              </PhotoCluster.Item>
                            ))
                          }
                        </PhotoCluster>
                      </Grid.Column>
                    )
                    : (
                      <Grid.Column width={8} style={{ height: "700px" }}>
                        <PhotoGallery
                          baseUrl={window._env_.PUBLIC_URL}
                          images={game.state.untaggedPictures}
                          selected={state.selected}
                          onArrowChange={(selected) => setState({ selected })}
                          actions={
                            <ClusterSelector
                              clusters={game.state.clusters}
                              onClusterSelect={(clusterID: string) => {

                                clusterID && game.tagPicture(clusterID, game.state.untaggedPictures[state.selected].id)
                              }} />
                          } />
                      </Grid.Column>
                    )
                }
                {
                  !game.state.completed
                    ? (
                      <Grid.Column
                        width={8}
                        style={{
                          backgroundColor: "white",
                          padding: ' 0',
                          position: 'relative'
                        }}
                        floated="right"
                      >
                        <div className='test' style={{ position: 'absolute', top: -30, right: 0, fontSize: '14px', color: '#f44b48' }}>
                          <FormattedHTMLMessage id='clusterphoto-rules:label' />
                        </div>
                        <div style={{
                          height: "700px",
                          overflowY: 'auto',
                          overflowX: 'hidden',
                        }}>
                          <GamePhotoClusters gameID={gameID} />
                        </div>
                      </Grid.Column>
                    )
                    : (
                      <Grid.Column width={8} style={{ height: "700px" }}>
                        {game && game.selectedCluster && <PhotoGallery
                          selected={state.selected}
                          onArrowChange={selected => setState({ selected })}
                          images={game.selectedCluster.pictures} />}
                      </Grid.Column>
                    )
                }
              </Grid.Row>
            </Grid>
          )}
        </Game>
      )}
    </Layout>
  )
}

export default observer(GameClassification);
