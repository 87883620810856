import { gql } from 'apollo-boost';

export default gql`
  query GameQuery($gameID:String!){
    game(gameID:$gameID) {
      id
      team {
        id
        name
      }
      position
      caseUrl
      completed
      isLeaderboardEnabled
      isTourOpen
      picturesLimit
      rankedClusters{
        clusterID
        rank
      }
      pictures {
        id
        url
        scene
      }
      untaggedPictures {
        id
        url
        scene
      }
      discoveredHotspots {
        id
        title
        attributes
        wastes
        image
        x
        y
      }
      clusters {
        id
        name
        unit
        pictures {
          id
          url
        }
      }
      leaderboard {
        id
        positions {
          rank
          score
          game {
            id
            team {
              id
              name
            }
          }
        }
      }
      reportDocuments {
        id
        url
        name
      }
      sessionDocuments {
        id
        url
        name
      }
      tourXML
    }
  }
`
