import React, { Fragment } from 'react';
import { arrayOf, shape, string, InferProps, number } from 'prop-types';
import range from 'lodash/range';

const propTypes = {
  pictures: arrayOf(shape({
    id: string.isRequired,
    url: string.isRequired
  }).isRequired),
  size: number.isRequired,
  anglesArray: arrayOf(number).isRequired,
  borderSize: number,
  style: CSSStyleSheet,
  baseUrl: string
}

type Props = InferProps<typeof propTypes>


const PicturesStack: React.FunctionComponent<Props> = ({ pictures, baseUrl="", size, anglesArray, borderSize, style }) => {

  const bordSize = borderSize || 5;

  const data = pictures ?
    pictures.slice().reverse().slice(0, size).reverse() :
    range(size).map(el => ({ id: el.toString(), url: '' }));

  const centeredStyle = {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    justifySelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center'
  }
  return (
    <div style={{
      position: 'relative', width: '100%', height: '100%',
      ...style,
      ...centeredStyle
    }}>
      {data.map((picture, index) => (
        <Fragment key={index} >
          <div className='centered' style={{
            position: 'absolute',
            transform: `rotate(${anglesArray[index]}deg)`,
            zIndex: (index * 2),
            width: '100%',
            height: '70%',
            display: 'flex',
            textAlign: 'center',
            backgroundColor: 'white',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)'
          }} />
          {
            picture.url ? (
              <img className='centered' id={picture.id} src={`${baseUrl}${picture.url}`} alt='' style={{
                width: `calc(100% - ${bordSize / 2}px)`,
                height: `calc(70% - ${bordSize / 2}px)`,
                position: 'absolute', zIndex: (index * 2 + 1),
                transform: `rotate(${anglesArray[index]}deg)`,
                display: 'flex',
                textAlign: 'center',
              }} />
            ) : (
                <div style={{
                  width: `calc(100% - ${bordSize / 2}px)`,
                  height: `calc(70% - ${bordSize / 2}px)`,
                  position: 'absolute', zIndex: (index * 2 + 1),
                  transform: `rotate(${anglesArray[index]}deg)`,
                  display: 'flex',
                  textAlign: 'center',
                  backgroundColor: '#eeeeee',
                }} />
              )
          }
        </Fragment>
      ))}
    </div>
  )
}

PicturesStack.defaultProps = {
  borderSize: 5,
} as Partial<Props>;

export default PicturesStack;
