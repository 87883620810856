import React from 'react';
import { FormattedHTMLMessage, WrappedComponentProps } from 'react-intl';
import { Route, RouteComponentProps } from "react-router";
import { Link } from 'react-router-dom';
import PLANTTOUR_ICON from '../assets/plantTourIcon.png';
import Game from '../components/Game';
import HydroplantSidebar from '../components/HydroplantSidebar';
import { Layout } from '../components/Layout';
import Overlay from '../components/Overlay';
import RectIconButton from '../components/RectIconButton';
import UserHydroplantMap from '../components/UserHydroplantMap';
import WelcomeTeamName from '../components/WelcomeTeamName';
import RouteHotspotDetails from './RouteHotspotDetails';


class RouteGamePlantMap extends React.Component<RouteComponentProps<{ gameID: string }> & WrappedComponentProps<"intl">> {
  render() {
    return (
      <Layout>
        {({ components: { Image, Segment } }) => (
          <Game gameID={this.props.match.params.gameID}>
            {({ game }) => (
              <div>
                <HydroplantSidebar game={game}
                  baseUrl={window._env_.PUBLIC_URL}
                  visible={game.sidebar}
                  closeCallback={() => game.closeSidebar()}
                  selectCallback={(index: number) => {
                    game.closeSidebar();
                    game.showPicture(index)
                  }} />
                {game.sidebar && <Overlay style={{ width: '100vw', height: '100vh', position: 'fixed' }} />}
                <div style={{ width: '80%', minHeight: '800px', margin: 'auto', paddingBottom: '70px' }}>
                  <Segment textAlign='right' basic style={{ padding: 0 }} >
                    <WelcomeTeamName>{game.state.team.name}</WelcomeTeamName>
                  </Segment>

                  <div style={{ width: '100%', margin: '1rem auto', backgroundColor: 'white' }}>
                    <UserHydroplantMap location={this.props.location.pathname} game={game} />
                  </div>
                  <div style={{ position: 'absolute', top: '2rem', right: 0, left: 0 }}>
                    <Route path={`${this.props.match.path}/hotspots/:hotspotID`} component={RouteHotspotDetails} />
                  </div>
                  <div style={{ position: 'absolute', bottom: '92px', left: '30px' }}>
                    <RectIconButton>
                      <Link to={`/participant/games/${this.props.match.params.gameID}/virtual-tour`}>
                        <Image src={PLANTTOUR_ICON} style={{ width: '100%', height: '77px', padding: '3px' }} />
                        <FormattedHTMLMessage id="plantTour:text" />
                      </Link>
                    </RectIconButton>
                  </div>
                </div>
              </div>
            )}
          </Game>
        )}
      </Layout>
    )
  }
}

export default RouteGamePlantMap;
