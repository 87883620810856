import { types, Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree";
import { Picture } from "./Picture";
import { Hotspot } from "./Hotspot";
import { Cluster } from "./Cluster";
import { Document } from "./Document";
import { Team } from "./Team";
import { Leaderboard } from "./Leaderboard";
import urlParse from 'url-parse';
import path from 'path';
import sortBy from 'lodash/sortBy';

export const GameState = types.model('GameState')
  .props({
    team: types.optional(Team, { name: '', id: '' }),
    position: types.maybeNull(types.model({
      id: 'init',
      x: 0,
      y: 0
    })),
    caseUrl: '',
    completed: false,
    isLeaderboardEnabled: false,
    isTourOpen: false,
    pictures: types.array(Picture),
    untaggedPictures: types.array(Picture),
    discoveredHotspots: types.array(Hotspot),
    notDiscoveredHotspots: types.array(Hotspot),
    clusters: types.array(Cluster),
    leaderboard: types.maybeNull(Leaderboard),
    reportDocuments: types.array(Document),
    sessionDocuments: types.array(Document),
    tourXML: '',
    clustersRanking: types.array(Cluster),
    picturesLimit: -1,
    rankedClusters: types.array(types.model({ clusterID: '', rank: -1 }))
  })

  .views(self => ({
    get tourBasepath() {
      const url = urlParse(self.tourXML);
      return `${url.protocol}//${url.host}${path.dirname(url.pathname)}`
    },
    get rankedClustersSorted() {
      return sortBy(self.rankedClusters, 'rank').map(cl => cl.clusterID)
    }
  }))

export interface GameStateInstance extends Instance<typeof GameState> { }
export interface GameStateSnapshotIn extends SnapshotIn<typeof GameState> { }
export interface GameStateSnapshotOut extends SnapshotOut<typeof GameState> { }
