import React, { FC, HTMLAttributes } from 'react';

const topMargin = 0;

const Overlay: FC<HTMLAttributes<HTMLElement>> = ({ children, style }) => (
  <div style={{
    position: 'fixed',
    top: topMargin,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 100000,
    ...style
  }}>
    <div style={{
      position: 'fixed',
      top: topMargin,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      backgroundColor: 'black',
      opacity: '.5'
    }} />
    {children}
  </div >
)

export default Overlay;