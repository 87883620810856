import { observer } from 'mobx-react';
import { InferProps, string } from 'prop-types';
import React, { useState, Fragment } from 'react';
import Admin from './Admin';
import Cluster from './Cluster';
import { Layout } from './Layout';
import PhotoCluster from './PhotoCluster';
import PhotoGallery from './PhotoGallery';
import OverlayGallery from './OverlayGallery';
import ClustersRankingSection from './ClustersRankingSection';

const props = {
  userID: string.isRequired
}

type AdminClassificationDebriefProps = InferProps<typeof props>;

const AdminClassificationDebrief: React.FC<AdminClassificationDebriefProps> = ({ userID }) => {

  const [state, setState] = useState({
    selected: 0
  })

  return (
    <Layout>
      {({ components: { Grid, Page } }) => (
        <Fragment>
          <Grid>
            <Grid.Row style={{ margin: '31px 0' }}>
              <Grid.Column
                width={7}
                style={{
                  backgroundColor: "white",
                  height: "850px",
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  position: 'relative',
                  left: '-5%'
                }}
                floated="right"
              >
                <Admin userID={userID}>
                  {({ admin }) => {
                    if (admin.selectedGame) {
                      return (
                        <PhotoCluster style={{ color: '#f44b48', fontWeight: 300, fontSize: '14px' }}>
                          {
                            admin.selectedGame.clusters.map((cluster, index) => (
                              <PhotoCluster.Item key={index}>
                                <Cluster cluster={cluster} active={!!admin.selectedCluster && (cluster.id === admin.selectedCluster.id)} onClick={(e, cluster) => admin.setSelectedCluster(cluster)} />
                              </PhotoCluster.Item>
                            ))
                          }
                        </PhotoCluster>
                      )
                    } else {
                      return null
                    }
                  }}
                </Admin>
              </Grid.Column>
              <Grid.Column width={9} style={{ height: "850px", left: '5%' }}>
                <Admin userID={userID}>
                  {({ admin }) => {
                    if (admin.selectedCluster) {
                      return <PhotoGallery
                        baseUrl={window._env_.PUBLIC_URL}
                        selected={state.selected}
                        onArrowChange={selected => setState({ selected })}
                        onPicureClick={() => {
                          admin.showPicture(state.selected);
                        }}
                        images={admin.selectedCluster.pictures} />
                    } else {
                      return null;
                    }
                  }}
                </Admin>

              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Admin userID={userID}>
                {({ admin }) => (
                  admin.selectedGame && <ClustersRankingSection clusters={admin.selectedGame.clusters}
                    onSelectCluster={() => {}}
                    rankedClusters={admin.selectedGame.rankedClustersSorted}
                    disabled={true} />
                )}
              </Admin>
            </Grid.Row>
          </Grid>
          <Admin userID={userID}>
            {({ admin }) => {
              if (admin.selectedGame && admin.selectedCluster && admin.image !== -1) {
                return (
                  <OverlayGallery pictures={admin.selectedCluster.pictures}
                    selectedImage={admin.image}
                    onClose={() => {
                      admin.hidePicture()
                    }}
                    onChange={(value: number) => {
                      admin.showPicture(value)
                    }}
                  />
                )
              } else {
                return null
              }
            }}
          </Admin>

        </Fragment>
      )}
    </Layout>
  )
}

export default observer(AdminClassificationDebrief);
