import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { useLocalStore } from "mobx-react";
import React from 'react';
import { AdminViewModel, AdminViewModelInstance } from "./AdminViewModel";
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

declare global {
  interface Window { API_ENDPOINT: string; }
}

const httpLink = createUploadLink({
  uri: window._env_.API_ENDPOINT,
});

const wsClient = new SubscriptionClient(
  window._env_.API_ENDPOINT.replace("https", "wss").replace("http", "ws"),
  {
    reconnect: true,
  },
)
const wsLink = new WebSocketLink(wsClient);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})

const storeContext = React.createContext<AdminViewModelInstance | null>(null);

const createStore = () => AdminViewModel.create({}, {
  client,
  wsClient,
});

export const StoreProvider: React.SFC = ({ children }) => {
  const store = useLocalStore(createStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) { throw new Error(`useStore must be used within a StoreProvider`) }
  else return store;
}
