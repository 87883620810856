import { observer } from "mobx-react";
import { arrayOf, element, func, InferProps, number, shape, string } from "prop-types";
import React from "react";
import ArrowController from "./ArrowController";
import { Layout } from "./Layout";
import Slideshow from "./Slideshow";

const propTypes = {
  images: arrayOf(
    shape({
      id: string,
      url: string
    }).isRequired
  ).isRequired,
  baseUrl: string,
  selected: number.isRequired,
  onArrowChange: func.isRequired,
  onPicureClick: func,
  actions: element
};

export type PhotoGalleryProps = InferProps<typeof propTypes>;

const PhotoGallery: React.SFC<PhotoGalleryProps> = ({
  images,
  baseUrl = "",
  actions,
  selected,
  onArrowChange,
  onPicureClick
}) => {

  return (
    <Layout>
      {({ components: { Divider, Picture, Image } }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            paddingBottom: "0.5rem",
            borderBottom: "1px solid rgba(128, 128, 128, 0.28)",
            height: "100%"
          }}
        >
          <div
            style={{
              flex: 1,
              position: "relative",
              display: "flex",
              padding: "2.5rem 0.75rem 1.5rem 0.75rem",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center"
            }}
          >
            <ArrowController
              max={images.length}
              value={selected}
              onChange={value => onArrowChange(value)}
              arrowBoxDimensions="medium"
            >
              {({ selected }) => (
                <Slideshow size={1} selected={selected}>
                  {images.map((image, index) => (
                    <Slideshow.Item key={index}>
                      <Picture
                        src={`${baseUrl}${image.url}`}
                        onClick={() => onPicureClick ? onPicureClick() : null}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "300px",
                          top: '50%',
                          position: 'relative',
                          transform: 'translateY(-50%)'
                        }}
                      />
                      {
                        actions && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "2rem",
                              right: "0.8rem",
                              minWidth: "50%",
                              textAlign: 'right'
                            }}
                          >
                            {actions}
                          </div>
                        )
                      }

                    </Slideshow.Item>
                  ))}
                </Slideshow>
              )}
            </ArrowController>
          </div>
          <Divider />
          <div
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              height: "100px",
              display: "flex",
              width: "95%",
              margin: "auto"
            }}
          >
            {images.map((image, index) => (
              <div
                style={{
                  display: "inline-flex",
                  margin: "0 10px"
                }}
                key={index}
                onClick={() => onArrowChange(index)}
              >
                <Image
                  src={`${baseUrl}${image.url}`}
                  style={{
                    height: "80px",
                    margin: "auto",
                    maxWidth: "none",
                    opacity: selected === index ? '0.26' : '1',
                    cursor: 'pointer'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default observer(PhotoGallery);
